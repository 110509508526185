<template>
<div classs="bg-white">
  <div v-if="loading" class="text-white text-center">
    <transition name="fade">
      <div class="text-white text-center" :style="style">
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
          <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
        </nav>
        <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Loading</h1>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
      </div>
    </transition>
  </div>
  <b-container fluid v-if="!loading" classs="bg-white">
      <b-row class="mb-4" style="background: #1E49E2; padding: 0px; height: 57px;">
        <b-col style="background: #1E49E2; height: 57px;">
          <!-- <div style="background: #1E49E2; font-size: 16px; color: white; height: 57px;"> -->
          <b-navbar style="background: #1E49E2; height: 57px;">
            <b-navbar-nav>
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item :to="{ name: 'Keywords' }" exact exact-active-class="active">Home</b-nav-item>
              </b-nav>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row v-if="disclaimer && $config.BUILD === 'full'">
      <b-col>
        <b-card class="my-3">
          <div class="mb-3">
            <h2>Risk disclaimer</h2>
            <div class="mb-3">
              <div><strong>Beta testing. Content generated by AI - may contain mistakes.</strong></div>
              <div><p>This is work in progress. We are building new AI powered trend detection features. Stay tuned.</p></div>
              <div><b-btn class="mr-2 ml-2" @click="disclaimer=false">OK</b-btn></div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
      <b-row class="mb-4" v-if="ready">
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'KeywordsLayout',
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'keywords', action: 'open keywords' })
    document.title = "Signals - AI Keywords"
    this.$store.commit('setBrand', 'Signals - AI Keywords')
    const image = _.sample(['fox', 'penguin', 'photographer', 'tent', 'wave', 'whale'])
    this.style = `background-image: url('/img/${image}.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;`
    await this.load()
  },
  data () {
    const data = {
      disclaimer: true,
      loading: true,
      loadingBackground: false,
      ready: false,
      style: ''
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.ready = false
      this.loading = false
      this.ready = true
    }
  }
}
</script>
<style scoped>
#top-menu-bar a:hover {
  font-weight: bold;
}
#top-menu-bar li a {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}
#top-menu-bar li .active {
  background: #00B8F5;
  font-weight: bold;
}
</style>
